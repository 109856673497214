import styled from "styled-components";

export const ReviewsLayout = styled.div`
  width: 1120px;
  height: 1018px;
  margin: 200px auto 0;

  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 1000px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 847px;
    margin: 120px 0 0;
    padding: 0 32px;
  }

  @media (max-width: 680px) {
    height: 686px;
    margin-top: 80px;
    padding: 0 16px;
    overflow: hidden;
  }
`;

export const ReviewCard = styled.div`
  position: absolute;
  top: ${({top}) => top};
  left: ${({left}) => left};
  ${({zIndex}) => zIndex && 'z-index: ' + zIndex + ';'};


  width: 528px;
  background: #FFFFFF;
  /* Shadow / Primary */

  box-shadow: 0px 134.283px 53.7132px rgba(0, 0, 0, 0.01), 0px 75.1985px 45.6563px rgba(0, 0, 0, 0.05), 0px 33.5708px 33.5708px rgba(0, 0, 0, 0.09), 0px 8.05699px 18.7996px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 5.03757px 15.1127px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 24px;


  @media (max-width: 1024px) {
    top: ${({topIPad}) => topIPad};
    left: ${({leftIPad}) => leftIPad};
    width: 365px;
    padding: 16px;
  }

  @media (max-width: 680px) {
    transition: transform 500ms ease-out;
    height: 406px;
    width: 90%;
    top: ${({topPhone}) => topPhone};
    left: ${({leftPhone}) => leftPhone};
  }
`;

export const Reviewer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px

  img {
    border-radius: 14px;
    width: 160px;
    height: 120px;
  }

  @media (max-width: 1024px) {
    gap: 16px
  }

  @media (max-width: 680px) {
    flex-direction: column;
    img {
      width: 100%;
      height: 150px;
    }
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    color: #252527;
  }

  span {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;

    text-transform: uppercase;

    color: #A7A7BF;
  }

  @media (max-width: 1024px) {
    gap: 6px;
    p {
      font-size: 20px;
      line-height: 120%;
    }

    span {
      font-size: 15px;
      line-height: 120%;
    }
  }
`;

export const ReviewText = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;

  color: #252527;

  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 120%;
  }
`;

export const MainHeading = styled.h2`
  position: absolute;
  top: 346px;

  font-family: "ivypresto-display",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110%;
  /* or 106px */

  color: #252527;
  width: 710px;
  z-index: 5;

  @media (max-width: 1024px) {
    width: 501px;
    font-size: 64px;
    top: 272px;
  }

  @media (max-width: 680px) {
    width: 288px;
    top: 26px;
    font-size: 48px;
  }
`;

export const Rating = styled.div`
  position: absolute;
  top: 312px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;

    color: #2C2C3A;
  }

  @media (max-width: 1024px) {
    top: 236px;
  }

  @media (max-width: 680px) {
    top: 0px;
  }
`;

export const ReviewToggler = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 6px;

  position: absolute;
  left: calc(50% - 25px);
  top: 640px;

  list-style: none;

  li {
    width: 8px;
    height: 8px;

    border: 1px solid #A7A7BF;
    border-radius: 50%;
  }
  li.active {
    background: #A7A7BF;
  }
`;

export const ScheduleSection = styled.div`
  width: 1120px;
  margin: 200px auto 0;

  display: flex;
  align-items: center;

  background: #F8F8FA;
  border: 1px solid #A7A7BF;
  border-radius: 20px;
  padding: 80px;

  @media (max-width: 1024px) {
    width: 92%;
    margin: 120px auto;
    padding: 88px 48px;
    justify-content: space-between;
  }

  @media (max-width: 680px) {
    width: 100%;
    border-radius: 0px;

    margin-top: 80px;
    padding: 48px 24px;

    flex-direction: column;
    gap: 24px;
    border-top: 1px solid #A7A7BF;
    border-bottom: 1px solid #A7A7BF;
    border-left: none;
    border-right: none;
  }
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    width: 244px;
  }
`;

export const ScheduleHead = styled.p`
  font-family: "ivypresto-display",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 110%;

  color: #252527;

  @media (max-width: 1024px) {
    font-size: 48px;
  }

  @media (max-width: 680px) {
    font-size: 32px;
    text-align: center;
  }
`;


export const ScheduleText = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  color: #252527;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 680px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
  }
`;

export const ScheduleForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const ScheduleInput = styled.input`
  width: 380px;
  height: 60px;

  background: #FFFFFF;
  border-radius: 12px;
  padding: 18px 24px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  color: #2C2C3A;

  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 120%;

    padding: 15px 24px;

    width: 300px;
    height: 48px;
  }

  @media (max-width: 680px) {
    width: 288px;
  }
`;

export const ScheduleSubmit = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 8px;

  width: 380px;
  height: 56px;

  border: 1px solid #252527;
  border-radius: 40px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;

  color: #252527;
  background-color: transparent;
  margin-top: 8px;


  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: #252527;
    color: #F8F8FA;
  }

  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 120%;

    padding: 15px 30px;

    width: 300px;
    height: 48px;
  }

  @media (max-width: 680px) {
    width: 288px;
  }
`;
