import {
  Frame,
  MainHeading,
  Name,
  Rating,
  ReviewCard,
  Reviewer,
  ReviewsLayout,
  ReviewText,
  ReviewToggler,
  ScheduleForm,
  ScheduleHead,
  ScheduleInput,
  ScheduleSection,
  ScheduleSubmit,
  ScheduleText,
} from './index.styles';
import StarsIcon from '../../../assets/images/homepage/stars.svg';
import { useState } from 'react';
import { postDemo } from '../../../services/church.service';

const ReviewObjs = [
  {
    name: 'Filmore Bouldes',
    designation: 'Pastor, C3 NYC',
    review:
      '“So much of church life is about helping people take a next step in their walk with Jesus. Togather’s platform provides us the tools to help people with that step and more. Every month we are seeing an increase in our new people retention in small groups.”',
    img: './static/images/homepage/filmore.png',
    top: '0px',
    left: 'calc(50% - 528px/2 + 264px)',

    topIPad: '0px',
    leftIPad: 'calc(100% - 396px)',

    topPhone: '200px',
    leftPhone: '16px',

    zIndex: '3',
  },
  {
    name: 'Brian Hatrick',
    designation: 'Volunteer Leader',
    review:
      '“This is the first time I felt I had a tool that helped me focus on the health of the people in my small group instead database reporting. The leader tools are awesome and it’s all so easy to use.”',
    img: './static/images/homepage/brian.png',
    top: '565px',
    left: 'calc(50% - 528px/2 + 296px)',

    topIPad: '412px',
    leftIPad: 'calc(100% - 396px)',

    topPhone: '200px',
    leftPhone: '16px',

    zIndex: '2',
  },
  {
    name: 'Alex Erlenbush',
    designation: 'Pastor, Revival',
    review:
      '“After speaking with Togather’s team and seeing what they’ve built, I couldn’t be more excited to use it at Revival and equip our leaders with it.”',
    img: './static/images/homepage/alex.png',
    top: '624px',
    left: 'calc(50% - 528px/2 - 296px)',

    topIPad: '614px',
    leftIPad: '32px',

    topPhone: '200px',
    leftPhone: '16px',

    zIndex: '1',
  },
];
const minSwipeDistance = 50;

const Reviews = ({ width, homepage }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [activeReview, setActiveReview] = useState(0);
  const [churchDemo, setChurchDemo] = useState({
    first_name: '',
    email: '',
    organization_name: 0,
    phone: '',
  });
  const [errorField, setErrorField] = useState('');

  const submitDemoForm = () => {
    postDemo({
      ...churchDemo,
      number_of_groups: 10,
    })
      .then((res) => {
        Calendly.initPopupWidget({
          url: 'https://calendly.com/gettogather/30mindemo',
        });
        setChurchDemo({
          first_name: '',
          email: '',
          organization_name: 0,
          phone: '',
        });
      })
      .catch((error) => {
        const err = error.response.data.errors;
        if (err) {
          if (err.length > 0)
            if (err[0].length > 0)
              if (
                err[0][0].length > 0 &&
                err[0][0][0] === 'church demo with this email already exists.'
              ) {
                Calendly.initPopupWidget({
                  url: 'https://calendly.com/gettogather/30mindemo',
                });
                setChurchDemo({
                  first_name: '',
                  email: '',
                  organization_name: 0,
                  phone: '',
                });
              }
        }
      });
  };

  const handleInputChange = (e) => {
    setChurchDemo({
      ...churchDemo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <ReviewsLayout>
        <MainHeading>Trusted by Church Leaders</MainHeading>
        <Rating>
          <StarsIcon />
          <p>on the App Store</p>
        </Rating>
        <div style={{ width: '100vw' }}>
          {ReviewObjs.map((rItem, index) => (
            <ReviewCard
              key={'review' + index}
              className="slide"
              top={rItem.top}
              left={rItem.left}
              topIPad={rItem.topIPad}
              leftIPad={rItem.leftIPad}
              topPhone={rItem.topPhone}
              leftPhone={rItem.leftPhone}
              zIndex={width <= 680 ? undefined : rItem.zIndex}
              style={
                width <= 680
                  ? {
                      transform:
                        ReviewObjs[activeReview].name === rItem.name
                          ? 'translateX(0px)'
                          : `translateX(${
                              (index < activeReview ? -1 : 1) * width
                            }px)`,
                    }
                  : {}
              }
              onTouchStart={(e) => {
                setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
                setTouchStart(e.targetTouches[0].clientX);
              }}
              onTouchMove={(e) => setTouchEnd(e.targetTouches[0].clientX)}
              onTouchEnd={(e) => {
                if (!touchStart || !touchEnd) return;
                const distance = touchStart - touchEnd;
                const isLeftSwipe = distance > minSwipeDistance;
                const isRightSwipe = distance < -minSwipeDistance;
                if (isLeftSwipe && activeReview < 2) {
                  setActiveReview(activeReview + 1);
                } else if (isRightSwipe && activeReview > 0) {
                  setActiveReview(activeReview - 1);
                }
              }}
            >
              <Reviewer>
                <img src={rItem.img} />
                <Name>
                  <p>{rItem.name}</p>
                  <span>{rItem.designation}</span>
                </Name>
              </Reviewer>
              <ReviewText>{rItem.review}</ReviewText>
            </ReviewCard>
          ))}
        </div>
        {width <= 680 && (
          <ReviewToggler>
            {ReviewObjs.map((rItem, index) => (
              <li
                key={'toggler' + index}
                onClick={() => setActiveReview(index)}
                className={
                  ReviewObjs[activeReview].name === rItem.name ? 'active' : ''
                }
              />
            ))}
          </ReviewToggler>
        )}
      </ReviewsLayout>
      {homepage && (
        <ScheduleSection>
          <Frame>
            <ScheduleHead>Schedule a Live Product Demo</ScheduleHead>
            <ScheduleText>
              Ready for growth? Schedule a conversation with one of our Small
              Groups experts.
            </ScheduleText>
          </Frame>
          <ScheduleForm>
            <ScheduleInput
              type="text"
              placeholder="Name"
              name="first_name"
              value={churchDemo.first_name || ''}
              onChange={handleInputChange}
            />
            <ScheduleInput
              type="email"
              placeholder="Your work email"
              name="email"
              value={churchDemo.email || ''}
              onChange={(e) => {
                const emailReg = new RegExp(
                  '^\\w+([\\.\\+-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
                );
                if (!emailReg.test(e.target.value)) {
                  setErrorField('email');
                } else {
                  setErrorField('');
                }
                handleInputChange(e);
              }}
            />
            <ScheduleInput
              type="text"
              placeholder="Organization name"
              name="organization_name"
              value={churchDemo.organization_name || ''}
              onChange={handleInputChange}
            />
            <ScheduleInput
              type="number"
              placeholder="Phone number (optional)"
              name="phone"
              value={churchDemo.phone || ''}
              onChange={handleInputChange}
            />
            <ScheduleSubmit
              disabled={
                !churchDemo.first_name ||
                !churchDemo.email ||
                !churchDemo.organization_name ||
                errorField !== ''
              }
              onClick={submitDemoForm}
            >
              Schedule Your Demo
            </ScheduleSubmit>
          </ScheduleForm>
        </ScheduleSection>
      )}
    </>
  );
};

export default Reviews;
